<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-green',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'Orders', icon: 'fa fa-box text-green', path: '/orders'}"/>
        <sidebar-item :link="{name: 'Products', icon: 'fa fa-archive text-green', path: '/products'}"/>
        <sidebar-item :link="{name: 'Gallery', icon: 'ni ni-ni ni-image text-green', path: '/gallery'}"/>
        <sidebar-item :link="{name: 'Vouchers', icon: 'ni ni-ni ni-tag text-green', path: '/vouchers'}"/>
        <sidebar-item :link="{name: 'Byme Codes', icon: 'ni ni-ni ni-tag text-green', path: '/byme-codes'}"/>
        <sidebar-item :link="{name: 'Byme Uploads', icon: 'ni ni-ni ni-tag text-green', path: '/byme-uploads'}"/>
        <sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-green', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Settings', icon: 'ni ni-settings text-green', path: '/settings'}"/>
        <!-- <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-green', path: '/icons'}"/> -->
        <!-- <sidebar-item :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-green', path: '/tables'}"/> -->
        <!-- <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>
        <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}"/>
        <sidebar-item :link="{name: 'Register', icon: 'ni ni-circle-08 text-pink', path: '/register'}"/> -->

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'blue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
