<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "@/firebase/firebaseInit";
import { version } from "../package.json";

export default {
  name: "app",
  components: { },
  metaInfo: {
    changed(metaInfo) {
      alert(metaInfo);
      firebaseApp.analytics().setCurrentScreen(metaInfo.title);
      firebaseApp.analytics().logEvent("page_view");
      firebaseApp.analytics().logEvent("screen_view", {
        app_name: this.isPWA() ? "pwa" : "web",
        screen_name: metaInfo.title,
        app_version: version
      });
    }
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      let uid = (user) ? user.uid : 'guest';
      firebaseApp.analytics().setUserId(uid);
      firebaseApp.analytics().setUserProperties({
          account_type: "Basic" // can help you to define audiences
        });
      this.$store.commit("updateUser", user);
      if (user) {
        this.$store.dispatch("getCurrentUser", user);
      }
    });
    // Dispatch stores
    // =================
    // this.$store.dispatch("getPost");
    this.$store.dispatch("getProduct");
  },
  mounted() {},
  methods: {
    isPWA() {
      return window && window.matchMedia("(display-mode: standalone)").matches;
    }
  }
}
</script>