import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
// import AuthLayout from "@/layout/AuthLayout";
import BookabookLayout from "@/layout/BookabookLayout";
import ShopLayout from "@/layout/ShopLayout";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "@/firebase/firebaseInit";
import { version } from "../package.json";
import store from './store'
Vue.use(Router);

const host = window.location.host;
const parts = host.split('.');
let firstLoad = true;

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "Home",
      component: parts[0] === 'byme' ? BookabookLayout : ShopLayout,
      children: [
        {
          path: "/",
          name: "Home",
          component: () => (parts[0] === 'byme')
            ? import("./views/Byme.vue")
            : (parts[0] === 'kreart')
              ? import("./views/Kreart.vue")
              : import("./views/Home.vue"),
        },
        {
          path: '/help-centre',
          component: () => import("./views/HelpCentre/HelpCentre.vue"),
        },
        {
          path: '/privacy-policy',
          component: () => import("./views/PrivacyPolicy.vue"),
        },
        {
          path: '/request-data-changes',
          component: () => import("./views/RequestDataChanges.vue"),
        },
        {
          path: '/help-centre/message',
          component: () => import("./views/HelpCentre/SubmitMessage.vue"),
        },
        {
          path: '/about-us',
          component: () => import("./views/HelpCentre/AboutUs.vue"),
        },
        {
          path: '/careers',
          component: () => import("./views/HelpCentre/Careers.vue"),
        },
        {
          path: '/company-profile',
          component: () => import("./views/HelpCentre/CompanyProfile.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "ourbooks",
      component: BookabookLayout,
      children: [
        {
          path: "/byme",
          name: "Byme",
          component: () => import("./views/Byme.vue"),
        },
        {
          path: "/kreart",
          name: "Kreart",
          component: () => import("./views/Kreart.vue"),
        },
        {
          path: "/ourbooks",
          name: "Our Books",
          meta: { title: 'Our Books' },
          component: () => import("./views/OurBooks.vue"),
        },
        {
          path: "/product/:id",
          name: "Product",
          component: () =>
            import("./views/Product.vue"),
        },
        {
          path: "/custombook/:id",
          name: "PersonalizedBook",
          component: () =>
            import("./views/PersonalizedBook.vue"),
        },
        {
          path: "/preview/:id",
          name: "PreviewPersonalizedBook",
          meta: { skipRouterAnalytics: true },
          component: () =>
            import("./views/PreviewPersonalizedBook.vue"),
        },
        {
          path: "/checkout",
          name: "checkout",
          meta: { title: 'Checkout' },
          component: () => import("./views/CustomCheckout.vue"),
        },
        {
          path: "/thankyou",
          name: "thankyou",
          meta: { title: 'Thank You' },
          component: () => import("./views/ThankYou.vue"),
        },
        {
          path: "byme-kit",
          name: "Byme-kit",
          meta: {
            id: "byme-kit",
            title: 'Byme-kit'
          },
          component: () => import("./views/CustomBookForm/BymeKit.vue"),
        },
        {
          path: "byme-kit-digital",
          name: "Byme-kit-digital",
          meta: {
            id: "byme-kit-digital",
            title: 'Byme-kit-digital'
          },
          component: () => import("./views/CustomBookForm/BymeKit.vue"),
        },
        {
          path: "/byme-upload",
          name: "byme-upload",
          component: () => import("./views/BymeUpload.vue"),
        },
        {
          path: "/kid-and-the-dragon",
          name: "KidAndTheDragon",
          meta: {
            id: "kid-and-the-dragon",
            title: 'Kid And The Dragon'
          },
          component: () => import("./views/CustomBookForm/KidAndTheDragon.vue"),
        },
        {
          path: "good-night",
          name: "GoodNight",
          meta: {
            id: "good-night",
            title: 'Good Night'
          },
          component: () => import("./views/CustomBookForm/GoodNight.vue"),
        },
        {
          path: "where-are-you",
          name: "WhereAreYou",
          meta: {
            id: "where-are-you",
            title: 'Where Are You'
          },
          component: () => import("./views/CustomBookForm/WhereAreYou.vue"),
        },
        {
          path: "where-are-you-birthday",
          name: "WhereAreYouBirthday",
          meta: {
            id: "where-are-you-birthday",
            title: 'Where Are You Birthday'
          },
          component: () => import("./views/CustomBookForm/WhereAreYouBirthday.vue"),
        },
        {
          path: "welcome-to-the-world",
          name: "WelcomeToTheWorld",
          meta: {
            id: "welcome-to-the-world",
            title: 'Welcome To The World'
          },
          component: () => import("./views/CustomBookForm/WelcomeToTheWorld.vue"),
        },
        {
          path: "a-very-dinosaur-birthday",
          name: "AVeryDinosaurBirthday",
          meta: {
            id: "a-very-dinosaur-birthday",
            title: 'A Very Dinosaur Birthday'
          },
          component: () => import("./views/CustomBookForm/AVeryDinosaurBirthday.vue"),
        },
        {
          path: "a-very-unicorn-birthday",
          name: "AVeryUnicornBirthday",
          meta: {
            id: "a-very-unicorn-birthday",
            title: 'A Very Unicorn Birthday'
          },
          component: () => import("./views/CustomBookForm/AVeryUnicornBirthday.vue"),
        },
        {
          path: "an-intergalactic-birthday-party",
          name: "AnIntergalacticBirthdayParty",
          meta: {
            id: "an-intergalactic-birthday-party",
            title: 'An Intergalactic Birthday Party'
          },
          component: () => import("./views/CustomBookForm/AnIntergalacticBirthdayParty.vue"),
        },
        {
          path: "bali",
          name: "Bali",
          meta: {
            id: "bali",
            title: 'Bali'
          },
          component: () => import("./views/CustomBookForm/Bali.vue"),
        },
        {
          path: "jakarta",
          name: "Jakarta",
          meta: {
            id: "jakarta",
            title: 'Jakarta'
          },
          component: () => import("./views/CustomBookForm/Jakarta.vue"),
        },
        {
          path: "happy-birthday",
          name: "Happy Birthday",
          meta: {
            id: "happy-birthday",
            title: 'Happy Birthday'
          },
          component: () => import("./views/CustomBookForm/HappyBirthday.vue"),
        },
        {
          path: "one-day-you-will-fly",
          name: "One Day You Will Fly",
          meta: {
            id: "one-day-you-will-fly",
            title: 'One Day You Will Fly'
          },
          component: () => import("./views/CustomBookForm/OneDayYouWillFly.vue"),
        },
        {
          path: "promoted-to-big-brother-sister",
          name: "Promoted To Big Brother Sister",
          meta: {
            id: "promoted-to-big-brother-sister",
            title: 'Promoted To Big Brother Sister'
          },
          component: () => import("./views/CustomBookForm/PromotedToBigBrotherSister.vue"),
        },
        {
          path: "ramadan-seru",
          name: "Ramadan Seru",
          meta: {
            id: "ramadan-seru",
            title: 'Ramadan Seru'
          },
          component: () => import("./views/CustomBookForm/RamadanSeru.vue"),
        },
        {
          path: "yogyakarta",
          name: "Yogyakarta",
          meta: {
            id: "yogyakarta",
            title: 'Yogyakarta'
          },
          component: () => import("./views/CustomBookForm/Yogyakarta.vue"),
        },
        // {
        //   path: "/BabyAnimals",
        //   name: "BabyAnimals",
        //   component: () => import("./views/CustomBookForm/BabyAnimals.vue"),
        // },
        {
          path: "/login",
          name: "login",
          component: () =>
            import("./views/Login.vue"),
        },
        {
          path: "/register",
          name: "register",
          component: () =>
            import("./views/Register.vue"),
        },
        {
          path: "/forgot-password",
          name: "forgotPassword",
          component: () =>
            import("./views/ForgotPassword.vue"),
        },
        {
          path: "/account",
          name: "account",
          meta: {
            requiresAuth: true,
          },
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Account.vue"),
        },
        {
          path: "/blogs",
          name: "Blog Index",
          component: () => import("./views/Blog/BlogIndex.vue"),
        },
        {
          path: "/blogs/:id",
          name: "Blog Post",
          component: () => import("./views/Blog/BlogPost.vue"),
        },
        {
          path: '/help-centre',
          component: () => import("./views/HelpCentre/HelpCentre.vue"),
        },
        {
          path: '/help-centre/message',
          component: () => import("./views/HelpCentre/SubmitMessage.vue"),
        },
        {
          path: '/about-us',
          component: () => import("./views/HelpCentre/AboutUs.vue"),
        },
        {
          path: '/careers',
          component: () => import("./views/HelpCentre/Careers.vue"),
        },
        {
          path: '/company-profile',
          component: () => import("./views/HelpCentre/CompanyProfile.vue"),
        },
      ],
    },
    {
      path: "/dashboard",
      redirect: "dashboard",
      component: DashboardLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            title: "Dashboard",
            requiresAuth: true,
            requiresAdmin: true,
          },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Dashboard.vue"),
        },
        {
          path: "/icons",
          name: "icons",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Icons.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
          },
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/UserProfile.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Orders.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/byme-codes",
          name: "byme-codes",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/BymeCodes.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/byme-uploads",
          name: "byme-uploads",
          component: () => import("./views/BymeUploads.vue"),
        },
        {
          path: "/byme-upload-view/:id",
          name: "View Byme Upload",
          component: () => import("./views/BymeUploadView.vue"),
          meta: {
            title: "View Byme Upload",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/view-orders/:id",
          name: "View Order",
          component: () => import("./views/ViewOrder.vue"),
          meta: {
            title: "View Order",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/vouchers",
          name: "vouchers",
          component: () => import("./views/Vouchers.vue"),
          meta: {
            title: "View Voucher",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/edit-voucher/:id",
          name: "Edit Voucher",
          component: () => import("./views/EditVoucher.vue"),
          meta: {
            title: "Edit Voucher",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/products",
          name: "products",
          component: () => import("./views/Products.vue"),
          meta: {
            title: "View Product",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/edit-product/:id",
          name: "Edit Product",
          component: () => import("./views/EditProduct.vue"),
          meta: {
            title: "Edit Product",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/gallery",
          name: "gallery",
          component: () => import("./views/Gallery.vue"),
          meta: {
            title: "View Product",
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Settings.vue"),
        },
        {
          path: "/maps",
          name: "maps",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Maps.vue"),
        },
        {
          path: "/tables",
          name: "tables",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Tables.vue"),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  //scrollToTop
  document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  //checkUserSigning
  let user = firebase.auth().currentUser;
  if (firstLoad) {
    await store.dispatch("getCurrentUser", user);
    firstLoad = false;
  }
  let admin = store.state.profileAdmin;
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next();
        }
        return next({ name: "account" });
      }
      return next();
    }
    return next({ name: "login" });
  }
  return next();
});

export default router;

const DEFAULT_TITLE = 'Bookabook.id | Personalized Books For Kids';
const RECURRING_TITLE = 'Bookabook.id';
router.afterEach((to) => {
  Vue.nextTick(() => {
    if (!to.meta.skipRouterAnalytics) {
      const title = (to.meta.title) ? to.meta.title + ' | ' + RECURRING_TITLE : DEFAULT_TITLE;
      document.title = title;
      firebaseApp.analytics().setCurrentScreen(title);
      firebaseApp.analytics().logEvent("page_view");
      firebaseApp.analytics().logEvent("screen_view", {
        app_name: (window && window.matchMedia("(display-mode: standalone)").matches) ? "pwa" : "web",
        screen_name: title,
        app_version: version
      });
    }
  });
});