<template>
    <div class="main-content bg-white">
        <!-- Navbar -->
        <header>
			<div class="wrap container">
				<div id="hamburger" v-on:click="display_menu()">
					<span></span>
					<span></span>
					<span></span>
				</div>
                <a :href="(domain == 'byme.bookabook.id') ? 'https://web.bookabook.id' : '/'" @click="handleScroll()"><img src="/img/brand/logo-bookabook.png"/></a>
				<nav id="menu" class="ml-5">
					<li @click="handleScroll()"><router-link to="/ourbooks">Our books</router-link></li>
					<li class="drop"><a v-on:click="display_drop_menu()">Age</a>
						<ul class="drop_menu" @click="handleScroll()">
							<router-link to="/ourbooks?age=0-3">0-3</router-link>
							<router-link to="/ourbooks?age=3-6">3-6</router-link>
							<router-link to="/ourbooks?age=6-9">6-9</router-link>
							<router-link to="/ourbooks?age=">All Ages</router-link>
						</ul>
					</li>
					<li class="drop"><a v-on:click="display_drop_menu()">By Language</a>
						<ul class="drop_menu" @click="handleScroll()">
							<router-link to="/ourbooks?language=English">English</router-link>
							<router-link to="/ourbooks?language=Bahasa%20Indonesia">Bahasa Indonesia</router-link>
						</ul>
					</li>
					<li class="drop"><a v-on:click="display_drop_menu()">Categories</a>
						<ul class="drop_menu" @click="handleScroll()">
							<router-link to="/ourbooks?category=Personalized%20Book">Personalized Book</router-link>
							<router-link to="/ourbooks?category=Flip-Flap%20Book">Flip-Flap Book</router-link>
							<router-link to="/ourbooks?category=Siblings%20Book">Siblings Book</router-link>
							<router-link to="/ourbooks?category=Sticker%20Book">Sticker Book</router-link>
							<router-link to="/ourbooks?category=Stickers">Stickers</router-link>
							<router-link to="/ourbooks?category=Hardcover">Hardcover</router-link>
							<router-link to="/ourbooks?category=Seasonal">Seasonal</router-link>
							<router-link to="/ourbooks?category=Kit">Kit</router-link>
							<router-link to="/ourbooks?category=Merchandise%20Karya%20Raya">Merchandise Karya Raya</router-link>
						</ul>
					</li>
                    <li @click="handleScroll()"><router-link to="/blogs">Blogs</router-link></li>
					<li @click="handleScroll()" class="flex-right">
                        <router-link to="/register" v-if="!user">Signup</router-link>
                        <router-link to="/account" v-if="user" class="btn">My Account</router-link>
                    </li>
					<li><router-link class="button" to="/checkout">Cart<span v-if="this.$store.state.cart.length>0" class="badge badge-danger">{{ this.$store.state.cart.length }}</span></router-link></li>
                </nav>
			</div>
		</header>
        <!-- Page content -->
        <div class="container pt-7">
            <slide-y-up-transition mode="out-in" origin="center top">
                    <router-view></router-view>
            </slide-y-up-transition>
        </div>
        <footer class="py-5">
            <div class="container">
                <div class="row justify-content-xl-between">
                    <!-- <div class="col-xl-12 align-items-right mascot">
                        <img width="200" src="/img/home/booka-mascot.png">
                    </div> -->
                    <div class="col-md-3">
                        <div class="sitemap-head"><h2><img src="/img/home/logo-horizontal.png" style="margin-top: -10px;"/></h2></div>
                        <div class="sitemap-links"><router-link to="#">Affilite program</router-link></div>
                        <div class="sitemap-links"><router-link to="/blogs">Blog</router-link></div>
                        <!-- <div class="sitemap-links"><router-link to="#">Collaborate</router-link></div>
                        <div class="sitemap-links"><router-link to="#">Press</router-link></div> -->
                        <div class="sitemap-links"><router-link to="/about-us">About Us</router-link></div>
                        <div class="sitemap-links"><router-link to="/company-profile">Company profile</router-link></div>
                        <div class="sitemap-links"><a href="https://byme.bookabook.id">Byme</a></div>
                        <div class="sitemap-links"><a href="https://kreart.bookabook.id">Kreart</a></div>
                    </div>
                    <div class="col-md-3">
                        <div class="sitemap-head"><h2>Help and support</h2></div>
                        <div class="sitemap-links"><router-link to="/help-centre">Contact Us</router-link></div>
                        <div class="sitemap-links"><router-link to="/help-centre">Delivery info</router-link></div>
                        <div class="sitemap-links"><router-link to="/help-centre">FAQs</router-link></div>
                    </div>
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-3">
                        <div class="sitemap-head"><h2>Follow us on</h2></div>
                        <div class="sitemap-links">
                            <span class="icon"><a target="_blank" href="https://www.instagram.com/bookabook.id"><img src="/img/home/icon-ig.png"></a></span>
                            <!-- <span class="icon"><a target="_blank" href="#"><img src="/img/home/icon-tiktok.png"></a></span>
                            <span class="icon"><a target="_blank" href="#"><img src="/img/home/icon-yt.png"></a></span> -->
                            <span class="icon"><a target="_blank" href="https://www.linkedin.com/company/bookabook-id/"><img src="/img/home/icon-linkedin.png"></a></span>
                            <span class="icon"><a target="_blank" href="https://web.whatsapp.com/send?text=Halo%20saya%20mau%20pesan%20buku&phone=6288801850011"><img src="/img/home/icon-wa.png"></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer class="py-3 yellow-bg">
            <div class="container">
                <div class="row align-items-center justify-content-xl-between">
                    <!-- <div class="col-xl-12 align-items-right mascot">
                        <img width="200" src="/img/home/booka-mascot.png">
                    </div> -->
                    <div class="col-xl-12">
                        <h3 class="copyright text-center text-xl-center">
                            © 2018-{{year}} Bookabook.id (PT Asia Aseta). All rights reserved

                        </h3>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>

  import { SlideYUpTransition } from 'vue2-transitions'
  import firebase from "firebase/app";
  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        user: null,
        domain: 'bookabook.id',
        year: new Date().getFullYear(),
        showMenu: false
      }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.user = firebase.auth().currentUser;
        this.domain = window.location.hostname;
        // console.log(this.domain);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.close_all_menu();
        },

		display_menu(){
			var body = document.getElementsByTagName("body")[0];
			(!body.classList.contains("display_menu")) ? body.classList.add("display_menu") : body.classList.remove("display_menu");
		},
		display_drop_menu(){
			var drop_menu = event.target.parentElement.getElementsByClassName("drop_menu")[0];
			var drop_menus = document.getElementsByClassName("drop_menu");

			Array.from(drop_menus).forEach(function(e){
				if(e != drop_menu){
					e.classList.remove("display");
				}
			});
			var lis = document.getElementById("menu").getElementsByTagName("li");
			Array.from(lis).forEach(function(e){
				e.style.marginTop = 0;
			});
			(!drop_menu.classList.contains("display")) ? drop_menu.classList.add("display") : drop_menu.classList.remove("display");
			if(window.innerWidth < 660 && drop_menu.classList.contains("display")) {
                console.log(event.target.parentElement.nextSibling.style.marginTop, drop_menu.clientHeight);
				event.target.parentElement.nextSibling.style.marginTop = drop_menu.clientHeight + "px";
			}
		},
		loaded(){
            document.getElementsByTagName("body")[0].style.overflowY = "hidden";
            this.load = true;
		},
        close_all_menu() {
            var lis = document.getElementById("menu").getElementsByTagName("li");
            Array.from(lis).forEach(function(e){
                e.style.marginTop = 0;
            });
            var drop_menus = document.getElementsByClassName("drop_menu");
            Array.from(drop_menus).forEach(function(e){
                e.classList.remove("display");
            });
            var body = document.getElementsByTagName("body")[0];
            if (body.classList.contains("display_menu")) body.classList.remove("display_menu");
        }
	}
  }
</script>
<style>
</style>