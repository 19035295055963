/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import axios from "axios";
import router from "./router";
import store from "./store";
import moment from "moment";

import ArgonDashboard from "./plugins/argon-dashboard";
import VueAxios from "vue-axios";
import Vue2Editor from "vue2-editor";
import VueContentPlaceholders from "vue-content-placeholders";

import firebase from "firebase/app";
import "firebase/auth";

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
Vue.use(VueContentPlaceholders);
Vue.use(Vue2Editor);
Vue.use(VueAxios, axios);
Vue.prototype.moment = moment;

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
