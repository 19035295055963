import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCpYorA8dMfE6-2ZLuMXGXNxMTSNNrh5BE",
  authDomain: "book-abook.firebaseapp.com",
  projectId: "book-abook",
  storageBucket: "book-abook.appspot.com",
  messagingSenderId: "590234279302",
  appId: "1:590234279302:web:a067de4b514097d353a007",
  measurementId: "G-0TG5110NY3"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();
const FieldValue = firebase.firestore.FieldValue;
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { analytics, FieldValue, timestamp };
export default firebaseApp;